import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import OutsideAlerter from "../../../../../shared/components/global/OutsideAlerter";
import StyledLink from "../../../../../shared/components/global/StyledLink";
import { Button, ButtonSizes } from "../../../../../shared/styles/button";
import { Container } from "../../../../../shared/styles/container";
import { Logo } from "../../../../../shared/styles/logo";
import {
  BurgerMenu,
  Header,
  MenuItem,
  NavbarButton,
  NavbarButtonContainer,
  NavbarContainer,
  NavbarContainerSubmenu,
  NavbarContainerSubmenuHideContainer,
  NavbarDropdownContainer,
} from "../../../../../shared/styles/navbar";
import { isUserPartOfGroup } from "../../../../../shared/utils/auth";
import BurgerMenuSVG from "/public/svg/burger.svg";
import TalentpoolSVG from "/public/svg/company/people.svg";
import TalentpoolLogo from "/public/svg/Logo.svg";
import NavDownSVG from "/public/svg/nav-down.svg";
import CompanySVG from "/public/svg/profile/company.svg";
import LoginSVG from "/public/svg/profile/key.svg";
import LogoutSVG from "/public/svg/profile/logout.svg";
import UserSVG from "/public/svg/profile/user.svg";

enum HoverItem {
  EVENTS,
  KARRIERE,
  UNTERNEHMEN,
  RECRUITING,
}

interface NavbarProps {
  darkmode?: boolean;
  headerAbsolute?: boolean;
  headerFixed?: boolean;
  headerFullscreen?: boolean;
  hideMenu?: boolean;
  toggleSidebar: any;
  sidebarOpened: boolean;
  isLoggedIn: boolean;
  handleSignOut: any;
}

export default function Navbar({
  darkmode,
  headerAbsolute,
  headerFixed,
  headerFullscreen,
  hideMenu,
  toggleSidebar,
  sidebarOpened,
  handleSignOut,
}: NavbarProps) {
  const router = useRouter();
  const [hoveredItem, setHoveredItem] = useState<HoverItem | null>(null);
  const [profileDropdownOpen, setProfileDropdownOpen] =
    useState<boolean>(false);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        // hide from console
      }
    };

    fetchUser();
  }, [router]);

  const handleClose = () => {
    if (document != null) {
      document
        .getElementsByTagName("html")[0]
        ?.classList.remove("sme-no-scroll");
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("sme-no-scroll");
    }
  };

  const shouldHidePrimaryButton = () => {
    if (typeof window != "undefined") {
      return window.location.pathname?.includes("/jobs");
    }
    return false;
  };

  return (
    <Header
      id="header"
      headerAbsolute={headerAbsolute}
      headerFixed={headerFixed}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <Container navbar backgroundColor="#FFFFFF">
        <NavbarContainer headerFullscreen={headerFullscreen}>
          <Logo darkmode={darkmode} onClick={() => handleClose()}>
            <StyledLink href="/" arialabel="easyboarding Logo">
              <TalentpoolLogo />
            </StyledLink>
          </Logo>
          {!hideMenu && (
            <>
              <StyledLink
                href="/speed-recruiting"
                aria-label="Speed-Recruiting"
              >
                <MenuItem inverted={darkmode}>Speed-Recruiting</MenuItem>
              </StyledLink>
              <StyledLink href="/talentpool" aria-label="Talentpool">
                <MenuItem inverted={darkmode}>Talentpool</MenuItem>
              </StyledLink>
              <StyledLink href="/jobs" aria-label="Jobbörse">
                <MenuItem inverted={darkmode}>Jobbörse</MenuItem>
              </StyledLink>
              <StyledLink href="/ausbildung" aria-label="Ausbildung">
                <MenuItem inverted={darkmode}>Ausbildung</MenuItem>
              </StyledLink>
              <StyledLink href="/companies" aria-label="Unternehmen entdecken">
                <MenuItem inverted={darkmode}>Unternehmen entdecken</MenuItem>
              </StyledLink>
              <StyledLink href="/blog" aria-label="Karrieretipps">
                <MenuItem
                  inverted={darkmode}
                  onMouseEnter={() => setHoveredItem(HoverItem.KARRIERE)}
                >
                  Blog <NavDownSVG />
                </MenuItem>
              </StyledLink>
              {/*  <StyledLink href="/recruiting-loesungen" aria-label="Talentpool">
                <MenuItem
                  inverted={darkmode}
                  onMouseEnter={() => setHoveredItem(HoverItem.RECRUITING)}
                >
                  Recruiting-Lösungen <NavDownSVG />
                </MenuItem>
              </StyledLink> */}
              {/*   <StyledLink href="/ueber-uns" aria-label="Über uns">
                <MenuItem
                  inverted={darkmode}
                >
                  Über uns
                </MenuItem>
              </StyledLink> */}
            </>
          )}
          <NavbarButtonContainer>
            {!shouldHidePrimaryButton() && (
              <StyledLink href="/register/talent/">
                <Button primary size={ButtonSizes.s}>
                  Bewerben
                </Button>
              </StyledLink>
            )}

            <OutsideAlerter fnCallback={() => setProfileDropdownOpen(false)}>
              <NavbarButton
                onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
              >
                <UserSVG /> <span>Mein Konto</span>
              </NavbarButton>
              <NavbarDropdownContainer showIf={profileDropdownOpen}>
                {user == null ? (
                  <div className="navbar-dropdown-list-container">
                    <StyledLink href="/login">
                      <li>
                        <div className="svg-wrapper">
                          <LoginSVG />
                        </div>
                        <dl>
                          <dt>Login</dt>
                          <dd>
                            Hast du einen easyboarding-Account? Hier einloggen.
                          </dd>
                        </dl>
                      </li>
                    </StyledLink>
                    <StyledLink href="/register">
                      <li>
                        <div className="svg-wrapper">
                          <TalentpoolSVG />
                        </div>
                        <dl>
                          <dt>Registrieren</dt>
                          <dd>
                            Hier kannst du dich registrieren und einen neuen
                            Account erstellen.
                          </dd>
                        </dl>
                      </li>
                    </StyledLink>
                  </div>
                ) : isUserPartOfGroup(user, "Agentur") ? (
                  <div className="navbar-dropdown-list-container">
                    <StyledLink href="/app/company/dashboard">
                      <li>
                        <div className="svg-wrapper">
                          <CompanySVG />
                        </div>
                        <dl>
                          <dt>Unternehmensprofil</dt>
                          <dd>
                            Aktualisiere dein Unternehmensprofil, um die
                            Reichweite zu erhöhen.
                          </dd>
                        </dl>
                      </li>
                    </StyledLink>
                    <StyledLink href="/app/company/talentpool">
                      <li>
                        <div className="svg-wrapper">
                          <TalentpoolSVG />
                        </div>
                        <dl>
                          <dt>Talentpool</dt>
                          <dd>Der Talentpool von easyboarding.</dd>
                        </dl>
                      </li>
                    </StyledLink>
                    {isUserPartOfGroup(user, "Admin") && (
                      <StyledLink href="/app/company/talentpool/admin">
                        <li>
                          <div className="svg-wrapper">
                            <TalentpoolSVG />
                          </div>
                          <dl>
                            <dt>Event-Dashboard</dt>
                            <dd>Der Event-Talentpool von easyboarding.</dd>
                          </dl>
                        </li>
                      </StyledLink>
                    )}
                    <li onClick={() => handleSignOut()}>
                      <div className="svg-wrapper">
                        <LogoutSVG />
                      </div>
                      <dl>
                        <dt>Ausloggen</dt>
                        <dd>Jetzt aus dem Unternehmensbereich abmelden.</dd>
                      </dl>
                    </li>
                  </div>
                ) : (
                  isUserPartOfGroup(user, "Talente") && (
                    <div className="navbar-dropdown-list-container">
                      <StyledLink href="/app/talent">
                        <li>
                          <div className="svg-wrapper">
                            <CompanySVG />
                          </div>
                          <dl>
                            <dt>Dein Profil</dt>
                            <dd>
                              Aktualisiere dein Profil, um von Agenturen
                              kontaktiert zu werden.
                            </dd>
                          </dl>
                        </li>
                      </StyledLink>
                      <li onClick={() => handleSignOut()}>
                        <div className="svg-wrapper">
                          <LogoutSVG />
                        </div>
                        <dl>
                          <dt>Ausloggen</dt>
                          <dd>Jetzt aus dem Profilbereich abmelden.</dd>
                        </dl>
                      </li>
                    </div>
                  )
                )}
              </NavbarDropdownContainer>
            </OutsideAlerter>
          </NavbarButtonContainer>

          <BurgerMenu
            sidebarOpened={sidebarOpened}
            inverted={darkmode}
            onClick={() => toggleSidebar()}
          >
            <BurgerMenuSVG />
          </BurgerMenu>
        </NavbarContainer>

        <NavbarContainerSubmenu showIf={hoveredItem != null}>
          <NavbarContainerSubmenuHideContainer
            showIf={hoveredItem == HoverItem.RECRUITING}
          >
            <StyledLink href="/recruiting-loesungen">
              <MenuItem inverted={darkmode}>Übersicht</MenuItem>
            </StyledLink>
            <StyledLink href="/jobs/company">
              <MenuItem inverted={darkmode}>Jobbörse</MenuItem>
            </StyledLink>
            <StyledLink href="/talentpool/arbeitgeber">
              <MenuItem inverted={darkmode}>Talentpool</MenuItem>
            </StyledLink>
          </NavbarContainerSubmenuHideContainer>
          <NavbarContainerSubmenuHideContainer
            showIf={hoveredItem == HoverItem.KARRIERE}
          >
            <StyledLink
              href="/blog/bewerbungstipps"
              aria-label="Bewerbungstipps"
            >
              <MenuItem inverted={darkmode}>Bewerbungstipps</MenuItem>
            </StyledLink>
            <StyledLink
              href="/blog/erfolgsgeschichten"
              aria-label="Erfolgsgeschichten"
            >
              <MenuItem inverted={darkmode}>Erfolgsgeschichten</MenuItem>
            </StyledLink>

            <StyledLink href="/blog/interviews" aria-label="Interviews">
              <MenuItem inverted={darkmode}>Interviews</MenuItem>
            </StyledLink>
            <StyledLink
              href="/blog/job-beschreibung"
              aria-label="Job-Beschreibung"
            >
              <MenuItem inverted={darkmode}>Job-Beschreibung</MenuItem>
            </StyledLink>
            <StyledLink
              href="/blog/fuer-arbeitgeber"
              aria-label="Für Arbeitgeber"
            >
              <MenuItem inverted={darkmode}>
                <span
                  style={{
                    textShadow: "1px 0 #202b57",
                    letterSpacing: "0.2px",
                  }}
                >
                  Für Arbeitgeber
                </span>
              </MenuItem>
            </StyledLink>
          </NavbarContainerSubmenuHideContainer>
        </NavbarContainerSubmenu>
      </Container>
    </Header>
  );
}
