import styled from "styled-components";
import StyledLink from "../../../../shared/components/global/StyledLink";
import { Button, ButtonSizes } from "../../../../shared/styles/button";
import {
  Container,
  ContainerContentStandard,
} from "../../../../shared/styles/container";
import { device } from "../../../../shared/styles/device";
import { Logo } from "../../../../shared/styles/logo";
import TalentpoolLogo from "/public/svg/Logo.svg";
import FacebookLogo from "/public/svg/social/fb.svg";
import InstagramLogo from "/public/svg/social/ig.svg";
import LinkedInLogo from "/public/svg/social/linked.svg";

interface FooterContainerProps {
  showCallToAction?: boolean;
}

const FooterContainer = styled.div<FooterContainerProps>`
  background-color: ${(props) =>
    props.showCallToAction
      ? props.theme.colors.primary
      : props.theme.colors.fontWhite};
  border-radius: 0px ${(props) => props.theme.borderRadiusSectionMobile} 0px 0px;
  width: 100%;

  @media ${device.tablet} {
    border-radius: 0px ${(props) => props.theme.borderRadiusSection} 0px 0px;
  }
`;

const FooterCallToAction = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
  text-align: center;

  h2 {
    color: ${(props) => props.theme.colors.fontBlack};
    font-size: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  p {
    color: ${(props) => props.theme.colors.fontBlack};
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media ${device.tablet} {
    padding-bottom: 80px;
    padding-top: 80px;
    h2 {
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 48px;
    }
    p {
      margin-bottom: 30px;
      font-size: 22px;
      max-width: 80%;
    }
  }
`;

const FooterContentContainer = styled.div<FooterContainerProps>`
  background-color: ${(props) => props.theme.colors.primaryBg};
  border-radius: 0px ${(props) => props.theme.borderRadiusSectionMobile} 0px 0px;
  margin-right: ${(props) => (props.showCallToAction ? "40px" : "0px")};

  h3,
  p,
  a,
  span,
  svg {
    color: ${(props) => props.theme.colors.fontWhite};
    fill: ${(props) => props.theme.colors.fontWhite};
  }

  @media ${device.tablet} {
    border-radius: 0px ${(props) => props.theme.borderRadiusSection} 0px 0px;
    margin-right: ${(props) => (props.showCallToAction ? "80px" : "0px")};
  }
`;
const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 0px;
  row-gap: 30px;

  .footer-description {
    font-size: 16px;
    line-height: 21px;

    .no-break {
      white-space: nowrap;
    }
  }

  a {
    display: flex;
    width: fit-content;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .footer-item-list {
    p {
      margin-top: 0;
      margin-bottom: 16px;
    }
    .h3-second {
      margin-top: 30px;
    }
  }
  @media ${device.tablet} {
    grid-template-columns: 3fr 2fr 2fr 2fr;
    column-gap: 32px;
    padding: 64px 0px;
  }
`;
const FooterBottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 12px 0px;

  p {
    margin: 0;
  }

  span {
    margin: 0 0.5rem;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 24px;
  }
`;
const FooterBottomBarLinks = styled.div`
  text-align: left;

  @media ${device.tablet} {
    text-align: right;
  }
`;
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 24px;

  svg {
    height: 24px;
    width: 24px;
  }

  @media ${device.tablet} {
    text-align: right;
  }
`;

export default function Footer({ showCallToAction }) {
  return (
    <FooterContainer showCallToAction={showCallToAction}>
      {showCallToAction && (
        <Container>
          <ContainerContentStandard>
            <FooterCallToAction>
              <h2>Du willst dabei sein?</h2>
              <p>
                Gib deiner Karriere einen Extra-Boost und starte schon morgen
                bei den Top-Tourismus-Unternehmen durch.
              </p>
              <StyledLink href="/register">
                <Button inverted size={ButtonSizes.l}>
                  Jetzt starten
                </Button>
              </StyledLink>
            </FooterCallToAction>
          </ContainerContentStandard>
        </Container>
      )}
      <FooterContentContainer showCallToAction={showCallToAction}>
        <Container>
          <ContainerContentStandard>
            <FooterContent>
              <div>
                <Logo darkmode>
                  <StyledLink href="/" arialabel="easyboarding Logo">
                    <TalentpoolLogo />
                  </StyledLink>
                </Logo>
                <p className="footer-description">
                  easyboarding – Dein Karriere-Boost in der Touristik. <br />
                  easyboarding ist ein Projekt von TRVL Counter und{" "}
                  <span className="no-break">STELL-MICH-EIN</span>, in
                  Kooperation mit dem Deutschen Reiseverband (DRV). <br />
                  <br />
                  Bist du auf der Suche nach einem Traumjob? <br />
                  Dann wirst du hier fündig.
                </p>
                <SocialContainer>
                  <a
                    href="https://www.facebook.com/Easyboarding-103309082559126"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                  >
                    <FacebookLogo />
                  </a>
                  <a
                    href="https://www.instagram.com/easyboarding.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                  >
                    <InstagramLogo />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/86392169/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <LinkedInLogo />
                  </a>
                </SocialContainer>
              </div>
              {/*    <div className='footer-item-list'>
                <h3>Events</h3>
                <StyledLink href="/speed-recruiting">
                  <p>
                    Speed-Recruiting
                  </p>
                </StyledLink>
                <h3 className='h3-second'>Talentpool</h3>
                <StyledLink href="/talentpool">
                  <p>
                    Talents
                  </p>
                </StyledLink>
                <StyledLink href="/talentpool/arbeitgeber/">
                  <p>
                    Für Arbeitgeber
                  </p>
                </StyledLink>
              </div> */}
              <div className="footer-item-list">
                <h3>Was wir bieten</h3>
                <StyledLink href="/speed-recruiting">
                  <p>Speed-Recruiting</p>
                </StyledLink>
                <StyledLink href="/talentpool">
                  <p>Talentpool</p>
                </StyledLink>
                <StyledLink href="/jobs" aria-label="Jobbörse">
                  <p>Jobbörse</p>
                </StyledLink>
                <StyledLink href="/companies" aria-label="Jobbörse">
                  <p>Unternehmensprofile</p>
                </StyledLink>
              </div>
              <div className="footer-item-list">
                <h3>Über easyboarding</h3>
                <StyledLink href="/blog">
                  <p>Blog</p>
                </StyledLink>
                <StyledLink href="/presse">
                  <p>Presse</p>
                </StyledLink>
                <StyledLink href="/ueber-uns">
                  <p>Über uns</p>
                </StyledLink>
                <StyledLink href="/kontakt">
                  <p>Kontakt</p>
                </StyledLink>
              </div>
              <div className="footer-item-list">
                <h3>Für Arbeitgeber</h3>
                <StyledLink href="/recruiting-loesungen/">
                  <p>Recruiting-Lösungen</p>
                </StyledLink>
                <StyledLink href="/blog/fuer-arbeitgeber">
                  <p>Arbeitgeberblog</p>
                </StyledLink>
              </div>
            </FooterContent>
            <FooterBottomBar>
              <p>© easyboarding 2024</p>
              <FooterBottomBarLinks>
                <StyledLink href="/datenschutz">Datenschutz</StyledLink>
                <span>|</span>
                <StyledLink href="/impressum">Impressum</StyledLink>
              </FooterBottomBarLinks>
            </FooterBottomBar>
          </ContainerContentStandard>
        </Container>
      </FooterContentContainer>
    </FooterContainer>
  );
}
