import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import OutsideAlerter from "../../../../../shared/components/global/OutsideAlerter";
import StyledLink from "../../../../../shared/components/global/StyledLink";
import TalentpoolLogo from "/public/svg/Logo.svg";
import BurgerMenuSVG from "/public/svg/burger.svg";
import TalentpoolSVG from "/public/svg/company/talentpool.svg";
import CompanySVG from "/public/svg/profile/company.svg";
import LoginSVG from "/public/svg/profile/key.svg";
import LogoutSVG from "/public/svg/profile/logout.svg";
// import RegisterSVG from '/public/svg/profile/register.svg';
import { Container } from "../../../../../shared/styles/container";
import { Logo } from "../../../../../shared/styles/logo";
import {
  BurgerMenu,
  Header,
  NavbarButton,
  NavbarButtonContainer,
  NavbarContainer,
  NavbarDropdownContainer,
  NavbarHeadline,
} from "../../../../../shared/styles/navbar";
import { isUserPartOfGroup } from "../../../../../shared/utils/auth";
import UserSVG from "/public/svg/profile/user.svg";

enum HoverItem {
  EVENTS,
  TALENTPOOL,
  KARRIERE,
  UNTERNEHMEN,
}
interface NavbarState {
  hoveredItem: HoverItem | null;
  profileDropdownOpen: boolean;
}
interface NavbarProps {
  darkmode?: boolean;
  headerAbsolute?: boolean;
  toggleSidebar: any;
  sidebarOpened: boolean;
  isLoggedIn: boolean;
  handleSignOut: any;
}
export default function NavbarBackend({
  darkmode,
  headerAbsolute,
  toggleSidebar,
  sidebarOpened,
  isLoggedIn,
  handleSignOut,
}: NavbarProps) {
  const [user, setUser] = useState<any>();
  const [hoveredItem, setHoveredItem] = useState<HoverItem | null>(null);
  const [profileDropdownOpen, setProfileDropdownOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      // isUser = isUserPartOfGroup(user, userGroup);
    };

    fetchUser();
  }, []);

  return (
    <Header
      id="header"
      headerAbsolute={headerAbsolute}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <Container navbar>
        <NavbarContainer headerFullscreen lightBg>
          <Logo darkmode={darkmode}>
            <StyledLink href="/" aria-label="Talentpool">
              <TalentpoolLogo />
            </StyledLink>
          </Logo>

          <NavbarHeadline>Unternehmens-Account</NavbarHeadline>

          <NavbarButtonContainer>
            <OutsideAlerter fnCallback={() => setProfileDropdownOpen(false)}>
              <NavbarButton
                onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
              >
                <UserSVG /> <span>Unternehmenskonto</span>
              </NavbarButton>
              <NavbarDropdownContainer showIf={profileDropdownOpen}>
                {!isLoggedIn ? (
                  <div className="navbar-dropdown-list-container">
                    <StyledLink href="/login">
                      <li>
                        <div className="svg-wrapper">
                          <LoginSVG />
                        </div>
                        <dl>
                          <dt>Login</dt>
                          <dd>
                            Sie haben bereits einen Account? Entdecken Sie neue
                            Inhalte!
                          </dd>
                        </dl>
                      </li>
                    </StyledLink>
                  </div>
                ) : (
                  <div className="navbar-dropdown-list-container">
                    <StyledLink href="/app/company/dashboard">
                      <li>
                        <div className="svg-wrapper">
                          <CompanySVG />
                        </div>
                        <dl>
                          <dt>Unternehmensprofil</dt>
                          <dd>
                            Aktualisieren Sie ihr Unternehmensprofil um ihre
                            Reichweite zu erhöhen.
                          </dd>
                        </dl>
                      </li>
                    </StyledLink>
                    <StyledLink href="/app/company/talentpool">
                      <li>
                        <div className="svg-wrapper">
                          <TalentpoolSVG />
                        </div>
                        <dl>
                          <dt>Talentpool</dt>
                          <dd>Der Talentpool von easyboarding.</dd>
                        </dl>
                      </li>
                    </StyledLink>
                    {isUserPartOfGroup(user, "Admin") && (
                      <StyledLink href="/app/company/talentpool/admin">
                        <li>
                          <div className="svg-wrapper">
                            <TalentpoolSVG />
                          </div>
                          <dl>
                            <dt>Event-Dashboard</dt>
                            <dd>Der Event-Talentpool von easyboarding.</dd>
                          </dl>
                        </li>
                      </StyledLink>
                    )}
                    <li onClick={() => handleSignOut()}>
                      <div className="svg-wrapper">
                        <LogoutSVG />
                      </div>
                      <dl>
                        <dt>Ausloggen</dt>
                        <dd>Jetzt aus dem Unternehmensbereich abmelden.</dd>
                      </dl>
                    </li>
                  </div>
                )}
              </NavbarDropdownContainer>
            </OutsideAlerter>
          </NavbarButtonContainer>

          <BurgerMenu
            sidebarOpened={sidebarOpened}
            inverted={darkmode}
            onClick={() => toggleSidebar()}
          >
            <BurgerMenuSVG />
          </BurgerMenu>
        </NavbarContainer>
      </Container>
    </Header>
  );
}
